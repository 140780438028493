import get from 'lodash/get';
import flatten from 'lodash/flatten';
import sumBy from 'lodash/sumBy';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import localize from '@src/i18n';

const nameLens = item => get(item, ['name']);
const idLens = item => get(item, ['id']);
const brandNameLens = item => get(item, ['brandName']);
const kindLens = item => get(item, ['kind']);
const unitNameLens = item => get(item, ['unitName']);
const kcalPerUnitLens = item => get(item, ['kcalPerUnit']);
const hasImageLens = item => get(item, ['hasImage']);
const foodTypeLens = item => get(item, ['foodType']);
const widthLens = item => get(item, ['image', 'width']);
const heightLens = item => get(item, ['image', 'height']);
const tagsLens = item => get(item, ['tags']);
const tagNameLens = tag => get(tag, ['name']);
const tagIdLens = tag => get(tag, ['id']);
const instructionsLens = item => get(item, ['instructions']);
const portionsLens = item => get(item, ['portions']);
const ingredientsLens = item => get(item, ['ingredients']);
const ingredientGroupsLens = item => get(item, ['ingredientGroups']);
const dryWeightGramsPerPortionLens = item =>
  get(item, ['dryWeightGramsPerPortion']);
const ingredientIdLens = ingredient => get(ingredient, ['ingredientId']);
const ingredientNameLens = ingredient => get(ingredient, ['foodstuff', 'name']);
const ingredientAlternativeNameLens = ingredient =>
  get(ingredient, ['alternativeName']);
const ingredientAmountLens = ingredient => get(ingredient, ['amount']);
const ingredientUnitLens = ingredient => get(ingredient, ['unit']);
const ingredientPossibleUnitsLens = ingredient =>
  get(ingredient, ['foodstuff', 'possibleUnits']);
const isFavouriteLens = item => get(item, ['isFavourite']);
const energyPercentageFromFatLens = item =>
  get(item, ['energyDistribution', 'energyPercentageFromFat']);
const energyPercentageFromCarbsLens = item =>
  get(item, ['energyDistribution', 'energyPercentageFromCarbs']);
const energyPercentageFromProteinLens = item =>
  get(item, ['energyDistribution', 'energyPercentageFromProtein']);
const energyPercentageFromAlcoholLens = item =>
  get(item, ['energyDistribution', 'energyPercentageFromAlcohol']);
const fatGramsPerUnitLens = item =>
  get(item, ['macronutrients', 'fatGramsPerUnit'], 0);
const carbsGramsPerUnitLens = item =>
  get(item, ['macronutrients', 'carbsGramsPerUnit'], 0);
const proteinGramsPerUnitLens = item =>
  get(item, ['macronutrients', 'proteinGramsPerUnit'], 0);
const alcoholGramsPerUnitLens = item =>
  get(item, ['macronutrients', 'alcoholGramsPerUnit'], 0);
const ratingAverageLens = item => get(item, ['rating', 'ratingAverage']);
const ratingCountLens = item => get(item, ['rating', 'ratingCount']);
const ratingRecipeIdLens = item => get(item, ['rating', 'recipeId']);
const userRecipeRatingLens = item => get(item, ['rating', 'userRecipeRating']);
const cookingTimeLens = item => get(item, ['cookingTimeMinutes']);
const recipeSourceLens = item => get(item, ['externalSource']);
const visibilityStatusLens = item => get(item, ['visibilityStatus']);
const imageVariantsLens = item => get(item, ['images', '0', 'variants']);
const recipeOwnerUserIdLens = item => get(item, ['userId', 'userIdString']);

const isRecipeHidden = item => visibilityStatusLens(item) === 'HIDDEN';

const getPortions = ({ recipeItem }) => portionsLens(recipeItem) || 0;

const getInstructions = ({ recipeItem }) => instructionsLens(recipeItem);

const getInstructionsForRecipeCreate = (instructionSteps, instructions) => {
  if (!isEmpty(instructionSteps)) return instructionSteps;
  return instructions ? [instructions] : [];
};

const getIngredients = ({ recipeItem }) => {
  const portions = portionsLens(recipeItem);
  const ingredients = ingredientsLens(recipeItem);

  return (
    ingredients &&
    ingredients.map(ingredient => {
      const amount = ingredientAmountLens(ingredient);
      const unit = ingredientUnitLens(ingredient);
      const possibleUnits = ingredientPossibleUnitsLens(ingredient);

      return {
        id: ingredientIdLens(ingredient),
        name:
          ingredientAlternativeNameLens(ingredient) ||
          ingredientNameLens(ingredient),
        amount: amount / portions,
        unit,
        kcalPerPortion:
          possibleUnits && unit
            ? ((amount / portions) * possibleUnits[unit]).toFixed()
            : undefined
      };
    })
  );
};

const getIngredientGroups = ({ recipeItem }) => {
  const portions = portionsLens(recipeItem);
  const ingredientGroups = ingredientGroupsLens(recipeItem);

  return (
    ingredientGroups?.length &&
    ingredientGroups.map(group => ({
      ...group,
      ingredients: group.ingredients.map(ingredient => {
        const amount = ingredientAmountLens(ingredient);
        const unit = ingredientUnitLens(ingredient);
        const possibleUnits = ingredientPossibleUnitsLens(ingredient);

        return {
          id: ingredientIdLens(ingredient),
          name:
            ingredientAlternativeNameLens(ingredient) ||
            ingredientNameLens(ingredient),
          amount: amount / portions,
          unit,
          kcalPerPortion:
            possibleUnits && unit
              ? ((amount / portions) * possibleUnits[unit]).toFixed()
              : undefined
        };
      })
    }))
  );
};

const getIngredientsRecipeCreate = (rawIngredients = [], recipeItem) => {
  const recipeIngredients = getIngredients(recipeItem);
  return rawIngredients.map((ing, index) => ({
    ...ing,
    amount: get(recipeIngredients[index].amount),
    kcalPerPortion: get(recipeIngredients[index].kcalPerPortion)
  }));
};

const getTags = ({ recipeItem }, isAdmin = false) => {
  const tags = isAdmin
    ? tagsLens(recipeItem) || {}
    : omit(tagsLens(recipeItem), 'systemProperty');

  const flattenedTags =
    tags && flatten(Object.keys(tags).map(item => tags[item]));
  return (
    flattenedTags &&
    flattenedTags.map(item => ({
      id: tagIdLens(item),
      name: tagNameLens(item)
    }))
  );
};

const getPossibleUnitDefault = item => ({
  unitName: unitNameLens(item),
  kcalPerUnit: kcalPerUnitLens(item)
});

const getFoodLog = (
  { recipeItem },
  isPostFavouriteFulfilled,
  isDeleteFavouriteFulfilled
) => {
  const possibleUnits = [getPossibleUnitDefault(recipeItem)];

  return {
    name: nameLens(recipeItem),
    brandName: brandNameLens(recipeItem),
    possibleUnits,
    kind: kindLens(recipeItem),
    isSingleUnit: possibleUnits.length === 1,
    unitName: unitNameLens(recipeItem),
    hasImage: hasImageLens(recipeItem),
    foodType: foodTypeLens(recipeItem),
    imageWidth: widthLens(recipeItem),
    imageHeight: heightLens(recipeItem),
    isFavourite:
      (isPostFavouriteFulfilled && !isDeleteFavouriteFulfilled) ||
      (isFavouriteLens(recipeItem) && !isDeleteFavouriteFulfilled),
    ratingAverage: ratingAverageLens(recipeItem),
    ratingCount: ratingCountLens(recipeItem),
    cookingTimeMinutes: cookingTimeLens(recipeItem),
    dryWeightGramsPerPortion: dryWeightGramsPerPortionLens(recipeItem),
    source: recipeSourceLens(recipeItem),
    isRecipeHidden: isRecipeHidden(recipeItem),
    visibilityStatus: visibilityStatusLens(recipeItem),
    images: imageVariantsLens(recipeItem)
  };
};

const getNutrition = recipeItem => {
  const kcalPerUnit = kcalPerUnitLens(recipeItem);
  const fatKcal = getKcal(energyPercentageFromFatLens(recipeItem), kcalPerUnit);
  const carbsKcal = getKcal(
    energyPercentageFromCarbsLens(recipeItem),
    kcalPerUnit
  );
  const proteinKcal = getKcal(
    energyPercentageFromProteinLens(recipeItem),
    kcalPerUnit
  );
  const alcoholPercentage = energyPercentageFromAlcoholLens(recipeItem);
  const alcoholKcal = getKcal(alcoholPercentage, kcalPerUnit);
  const fatGramsPerUnit = fatGramsPerUnitLens(recipeItem);
  const carbsGramsPerUnit = carbsGramsPerUnitLens(recipeItem);
  const proteinGramsPerUnit = proteinGramsPerUnitLens(recipeItem);
  const alcoholGramsPerUnit = alcoholGramsPerUnitLens(recipeItem);

  const nutrition = [
    {
      id: 'fat',
      name: localize('label_nutrition_fat'),
      kcal: Math.round(fatKcal),
      percentage: getPercentage(fatKcal, kcalPerUnit),
      grams: Math.round(fatGramsPerUnit)
    },
    {
      id: 'carbs',
      name: localize('label_nutrition_carbohydrates'),
      kcal: Math.round(carbsKcal),
      percentage: getPercentage(carbsKcal, kcalPerUnit),
      grams: Math.round(carbsGramsPerUnit)
    },
    {
      id: 'protein',
      name: localize('label_nutrition_protein'),
      kcal: Math.round(proteinKcal),
      percentage: getPercentage(proteinKcal, kcalPerUnit),
      grams: Math.round(proteinGramsPerUnit)
    }
  ];

  if (alcoholKcal) {
    const alcohol = {
      id: 'alcohol',
      name: localize('label_nutrition_alcohol'),
      kcal: Math.round(alcoholKcal),
      percentage: getPercentage(alcoholKcal, kcalPerUnit),
      grams: Math.round(alcoholGramsPerUnit)
    };
    nutrition.push(alcohol);
  }

  return nutrition;
};

const isNutrition = recipeItem => {
  const nutrition = getNutrition(recipeItem);

  return !!(sumBy(nutrition, 'kcal') || sumBy(nutrition, 'percentage'));
};

const getKcal = (energyPercentage, kcal) =>
  (Math.round((energyPercentage * 1000) / 100) / 1000) * kcal || 0;

const getPercentage = (kcal, kcalPerUnit) =>
  Math.round((kcal / kcalPerUnit) * 100) || 0;

// Returns the recipe rating; Using only recipeItem.rating.userRecipeRating
// causes delay when setting recipe rating (due to waiting for API response).
// Use local rating state for faster UI.
const getUserRating = ({ recipeItem, userRatingRecipeId, userRating }) => {
  const recipeId = ratingRecipeIdLens(recipeItem);
  const rating = userRecipeRatingLens(recipeItem);
  const recipeRating = userRating?.recipeRating;

  if (!recipeId && !userRatingRecipeId) {
    return 0;
  }

  if (recipeId === userRatingRecipeId) {
    return !recipeRating && recipeRating !== 0 ? rating : recipeRating;
  }

  return rating > -1 ? rating : recipeRating;
};

export default {
  getFoodLog,
  getTags,
  getInstructions,
  getInstructionsForRecipeCreate,
  getIngredients,
  getIngredientGroups,
  getIngredientsRecipeCreate,
  getPortions,
  dryWeightGramsPerPortionLens,
  kcalPerUnitLens,
  idLens,
  recipeOwnerUserIdLens,
  getNutrition,
  isNutrition,
  getUserRating
};
