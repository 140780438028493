import { foodSearchTypes } from '@ducks/foodSearch';
import types from './types';
import initialState from './initialState';

const searchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SWITCH: {
      return { ...state, [action.id]: !action.checked };
    }
    case foodSearchTypes.RESET_SEARCH_RESULT: {
      return {
        ...state,
        searchFoodstuffFilterSwitch: true,
        searchRecipeFilterSwitch: true
      };
    }
    default:
      return state;
  }
};

export default searchFilterReducer;
