import types from './types';
import initialState from './initialState';

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SELECTED_TAG: {
      return {
        ...state,
        selected: action.tag === state.selected ? null : action.tag
      };
    }
    default:
      return state;
  }
};

export default tagsReducer;
