import types from './types';
import initialState from './initialState';

const sitemapReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_SITEMAP_FULFILLED: {
      return {
        items: action.response,
        error: undefined
      };
    }
    case types.CREATE_SITEMAP_REJECTED: {
      return {
        ...state,
        error: {
          message: action.error.message
        }
      };
    }
    default:
      return state;
  }
};

export default sitemapReducer;
