import types from './types';

const onSwitchChange = (id, checked) => ({
  type: types.CHANGE_SWITCH,
  id,
  checked
});

export default {
  onSwitchChange
};
