import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const tabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SELECTED: {
      return { ...state, [action.id]: action.tab };
    }
    case modalsTypes.HIDE_MODAL: {
      if (action.id === modals.LOG_RECIPE_MODAL_ID) {
        return { ...state, logRecipeTab: initialState.logRecipeTab };
      }
      if (action.id === modals.LOG_DRAWER_MODAL_ID) {
        return { ...state, favouritesTab: initialState.favouritesTab };
      }
      return state;
    }
    default:
      return state;
  }
};

export default tabsReducer;
