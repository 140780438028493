import { getSitemap as sitemap } from '@src/utils/helpers/wsCalls';

import types from './types';

const sitemapFulfilled = response => ({
  type: types.CREATE_SITEMAP_FULFILLED,
  response
});

const sitemapRejected = error => ({
  type: types.CREATE_SITEMAP_REJECTED,
  error: {
    message: error.message
  }
});

function getSitemap() {
  return dispatch => {
    const fulfilled = response => dispatch(sitemapFulfilled(response));
    const rejected = error => dispatch(sitemapRejected(error));

    return sitemap(fulfilled, rejected);
  };
}

export default {
  getSitemap
};
