import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const similarRecipesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SIMILAR_RECIPES_PENDING: {
      return {
        ...state,
        recipes: [],
        getStatus: requestStatus.pending
      };
    }
    case types.GET_SIMILAR_RECIPES_FULFILLED: {
      return {
        ...state,
        recipes: action.response
          .filter(
            r =>
              r.hasImage &&
              r.foodType === 'SYSTEM_RECIPE' &&
              action.recipeId !== Number(r.id)
          )
          .slice(0, 4),
        getStatus: requestStatus.fulfilled
      };
    }
    case types.GET_SIMILAR_RECIPES_REJECTED: {
      return {
        ...state,
        getStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    default:
      return state;
  }
};

export default similarRecipesReducer;
