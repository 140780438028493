import types from './types';

const sectionsListFulfilled = response => ({
  type: types.CREATE_SECTIONS_LIST_FULFILLED,
  response
});

const sectionsListRejected = error => ({
  type: types.CREATE_SECTIONS_LIST_REJECTED,
  error: {
    message: error.message
  }
});

const sectionsListPending = () => ({
  type: types.CREATE_SECTIONS_LIST_PENDING
});

function getPaywallSectionsList(id) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(sectionsListFulfilled(response));
    };

    const rejected = error => dispatch(sectionsListRejected(error));

    dispatch(sectionsListPending());

    return wsCalls().getPaywallSectionsList(fulfilled, rejected, id);
  };
}

function getSalesSectionsList(id) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(sectionsListFulfilled(response));
    };
    const rejected = error => dispatch(sectionsListRejected(error));
    dispatch(sectionsListPending());
    return wsCalls().getSalesSectionsList(fulfilled, rejected, id);
  };
}

const clearSectionsList = () => ({ type: types.CLEAR_SECTIONS_LIST });

export default {
  getSalesSectionsList,
  getPaywallSectionsList,
  clearSectionsList
};
