import types from './types';
import initialState from './initialState';

const relatedArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_RELATED_ARTICLES_FULFILLED: {
      return {
        ...state,
        error: undefined,
        items: action.response
      };
    }
    case types.CREATE_RELATED_ARTICLES_REJECTED: {
      return {
        ...state,
        error: {
          message: action.error.message
        }
      };
    }
    case types.CLEAR_RELATED_ARTICLES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default relatedArticlesReducer;
