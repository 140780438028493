import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const sectionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_SECTIONS_LIST_FULFILLED: {
      return {
        ...state,
        error: undefined,
        sections: [...state.sections, { ...action.response }],
        requestStatus: requestStatus.fulfilled
      };
    }
    case types.CREATE_SECTIONS_LIST_PENDING: {
      return {
        ...state,
        error: undefined,
        requestStatus: requestStatus.pending
      };
    }
    case types.CREATE_SECTIONS_LIST_REJECTED: {
      return {
        ...state,
        requestStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    case types.CLEAR_SECTIONS_LIST: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default sectionsListReducer;
