import types from './types';

const changeSelectedTab = (id, tab) => ({
  type: types.CHANGE_SELECTED,
  id,
  tab
});

export default {
  changeSelectedTab
};
