import types from './types';

const relatedArticlesFulfilled = response => ({
  type: types.CREATE_RELATED_ARTICLES_FULFILLED,
  response
});

const relatedArticlesRejected = error => ({
  type: types.CREATE_RELATED_ARTICLES_REJECTED,
  error: {
    message: error.message
  }
});

function getRelatedArticles(isWellobe, id, authorId, articlesNumber = 9) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(relatedArticlesFulfilled(response));
    const rejected = error => dispatch(relatedArticlesRejected(error));

    const size = articlesNumber ? `?size=${articlesNumber}` : '';
    const authors = authorId ? `&author=${authorId}` : '';

    const params = `${size}${authors}`;

    return authorId
      ? wsCalls().getArticleCollection(fulfilled, rejected, params, isWellobe)
      : wsCalls().getRelatedArticles(fulfilled, rejected, isWellobe, id);
  };
}

const clearRelatedArticles = () => ({ type: types.CLEAR_RELATED_ARTICLES });

export default {
  getRelatedArticles,
  clearRelatedArticles
};
