import types from './types';

const changeSelectedTag = tag => ({
  type: types.CHANGE_SELECTED_TAG,
  tag
});

export default {
  changeSelectedTag
};
