import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const similarRecipesFulfilled = (response, recipeId) => ({
  type: types.GET_SIMILAR_RECIPES_FULFILLED,
  response,
  recipeId
});

const similarRecipesRejected = error => ({
  type: types.GET_SIMILAR_RECIPES_REJECTED,
  error: {
    message: error.message
  }
});

const similarRecipesPending = () => ({
  type: types.GET_SIMILAR_RECIPES_PENDING
});

function getSimilarRecipes(product, filters, recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response =>
      dispatch(similarRecipesFulfilled(response, recipeId));
    const rejected = error => {
      dispatch(similarRecipesRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(similarRecipesPending());
    return wsCalls().getRecipesAdvanced(
      fulfilled,
      rejected,
      getUserId(),
      product,
      filters
    );
  };
}

export default {
  getSimilarRecipes
};
