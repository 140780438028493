export default {
  pending: false,
  shareRecipeTokenPending: false,
  recipeItem: {},
  putRecipePending: false,
  putRecipeFoodtypePending: false,
  userRating: null,
  userRatingRecipeId: null,
  error: undefined,
  id: undefined,
  importSharedRecipeStatus: undefined
};
